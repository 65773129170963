import React, { useState } from "react";
import {
  IntroBg,
  IntroContainer,
  VideoBg,
  IntroContent,
  IntroH1,
  IntroP,
  ButtonWrapper,
  ArrowForward,
  ArrowRight,
} from "./IntroElements";
import { RouterButton } from "../ButtonElements";
import IntroVideo from "../../videos/intro_video1.mp4";

const IntroSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <>
      <IntroContainer id="home">
        <IntroBg>
          <VideoBg
            autoPlay
            loop
            muted
            playsInline
            webkit-playsinline
            src={IntroVideo}
            type="video/mp4"
          />
        </IntroBg>

        <IntroContent>
          <IntroH1>Welcome to Deivam Sarees</IntroH1>
          <IntroP>
            Here you will find the latest and trendiest sarees. We have
            clothings for all genders and ages originating from Kanchipuram.
            Don't hesitate to make a request, all our products are in stock.
          </IntroP>
          <ButtonWrapper>
            <RouterButton
              to="/shop"
              onMouseEnter={onHover}
              onMouseLeave={onHover}
              primary="true"
              dark="true"
            >
              Shop now {hover ? <ArrowForward /> : <ArrowRight />}
            </RouterButton>
          </ButtonWrapper>
        </IntroContent>
      </IntroContainer>
    </>
  );
};

export default IntroSection;
