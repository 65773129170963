import React from "react";
import styled from "styled-components";
const { REACT_APP_API_HOST } = process.env;
const Confirmation = ({
  showItem,
  setShowItem,
  images,
  selectedItem,
  setSwitchState,
  response,
}) => {
  return (
    <>
      <ItemWrapper showItem={showItem}>
        {response.status !== 200 ? (
          <ItemContent>
            <h2>An error has occured!</h2>
            <h3> Your request could not be processed, please try again.</h3>
            <p>Probably article is sold out.</p>
            <OkButton
              onClick={() => {
                window.history.back();
              }}
            >
              Try again
            </OkButton>
          </ItemContent>
        ) : (
          <ItemContent>
            <h2>Thank you, your request has been recieved!</h2>
            <h3> We will get in touch with you as soon as possible.</h3>
            <h4>You requested: </h4>
            <PreviewImage
              src={
                REACT_APP_API_HOST +
                "/api/v1/article/image/thumbnail/" +
                images[0].path
              }
              alt="Not found"
            />
            <p>{selectedItem.name}</p>
            <OkButton
              onClick={() => {
                window.history.back();
              }}
            >
              Continue shopping
            </OkButton>
          </ItemContent>
        )}
      </ItemWrapper>
    </>
  );
};

export default Confirmation;

const OkButton = styled.button`
  padding: 10px 24px;
  color: #fff;
  border: none;
  background: #141414;
`;

const ItemWrapper = styled.div`
  width: 500px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  z-index: 100;
  border-radius: 10px;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);

  h4 {
    margin-top: 20px;
  }

  @media screen and (max-width: 768px) {
    width: 350px;
    height: 400px;
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 0.4fr 1fr;
  }
`;

const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  line-height: 1.8;
  color: #141414;
  padding: 20px 10px;

  p {
    margin-bottom: 1rem;
  }
`;

const PreviewImage = styled.img`
  height: 80px;
  width: 80px;
`;
