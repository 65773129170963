import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import styled from "styled-components";
import { css } from "@emotion/react";
import MoonLoader from "react-spinners/MoonLoader";

const { REACT_APP_API_HOST } = process.env;

const override = css`
  display: block;
  position: relative;
  border-color: #000000;
`;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const Itempreview = ({
  showItem,
  setShowItem,
  images,
  selectedItem,
  setSwitchState,
  currency,
}) => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState([]);

  const handleClick = (image) => {
    if (image !== selectedImage) {
      setLoading(true);
      setSelectedImage(image);
    }
  };

  const customStyle = {
    height: "100%",
    width: "100%",
    marginBottom:
      windowDimensions.width < 768 && windowDimensions.width > 360 ? "7vh" : 0,
  };

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    setSelectedImage(images[0]);
  }, [images]);

  return (
    <ItemWrapper showItem={showItem}>
      <LoadHolder>
        <MoonLoader
          color="#000000"
          loading={loading}
          css={override}
          size={50}
        />
      </LoadHolder>
      <TransformWrapper
        limitToBounds={true}
        initialScale={windowDimensions.width < 1000 ? 1.21 : 1.18}
        initialPositionX={windowDimensions.width < 1000 ? -50 : -20}
        initialPositionY={windowDimensions.width < 1000 ? 0 : -80}
        minScale={windowDimensions.width < 1000 ? 1 : 1.18}
      >
        <TransformComponent wrapperStyle={customStyle}>
          <StyledImage
            src={
              REACT_APP_API_HOST +
              (windowDimensions.width < 1000
                ? "/api/v1/article/image/high/"
                : "/api/v1/article/image/original/") +
              selectedImage.path
            }
            alt="test"
            onLoad={() => setLoading(false)}
          />
        </TransformComponent>
      </TransformWrapper>
      <ThumbnailSection>
        {images.map((image) => (
          <ImagePreview key={image.id} onClick={() => handleClick(image)}>
            <PreviewImage
              src={
                REACT_APP_API_HOST +
                "/api/v1/article/image/thumbnail/" +
                image.path
              }
              alt="test"
            />
          </ImagePreview>
        ))}
      </ThumbnailSection>

      <ItemContent>
        <h1>{selectedImage.description}</h1>
        <p>{selectedItem.name}</p>
        <ItemPrice>
          {(selectedItem.price * currency.rate).toFixed(2)}
          &nbsp;{currency.countryCode}
        </ItemPrice>
        <SpecArea>
          <Spec>{selectedItem.text1}</Spec>
          <Spec>{selectedItem.text2}</Spec>
          <Spec>{selectedItem.text3}</Spec>
          <Spec>{selectedItem.text4}</Spec>
        </SpecArea>
        <RequestButton
          soldOut={selectedItem.inStock === 0}
          onClick={() => {
            if (selectedItem.inStock !== 0) setSwitchState("submit");
          }}
        >
          Request now
        </RequestButton>
      </ItemContent>
      <CloseItemPreviewButton
        aria-label="Close"
        onClick={() => window.history.back()}
      />
    </ItemWrapper>
  );
};

export default Itempreview;

const RequestButton = styled.button`
  margin-top: 5%;
  padding: 10px 24px;
  color: #fff;
  border: none;
  background: ${({ soldOut }) => (soldOut ? "#8b8b8b" : "#141414")};
`;

const ItemWrapper = styled.div`
  width: 100%;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: grid;
  grid-template-columns: 1fr 0.1fr 1fr;
  z-index: 100;
  border-radius: 10px;
  overflow: hidden;
  max-width: 90vw;
  max-height: 90vh;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);

  h1 {
    margin-bottom: -5px;
  }

  @media screen and (max-width: 768px) {
    max-width: 90vw;
    max-height: 90%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
`;

const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  line-height: 1.8;
  color: #141414;
  padding-bottom: 5px;
`;

const CloseItemPreviewButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 1;
`;

const StyledImage = styled.img`
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
`;

const PreviewImage = styled.img`
  height: 80px;
  width: 80px;
  @media screen and (max-width: 360px) {
    width: 50px;
    height: 50px;
  }
`;

const ImagePreview = styled.div`
  height: 80px;
  flex-direction: column;
  width: fit-content;
  display: grid;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    display: inline;
  }
`;

const ThumbnailSection = styled.div`
  flex-direction: row;
`;

const LoadHolder = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 999;
  transform: translate(-50%, -50%);
`;

const SpecArea = styled.div`
  margin-top: 5px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
`;

const Spec = styled.div`
  padding-top: 5px;
  display: block;
  line-height: 0.9rem;
  font-size: 0.8rem;
`;

const ItemPrice = styled.h4`
  display: block;
  font-size: 0.8rem;
  text-align: center;
  font-family: "Poppins", sans-serif;
  @media screen and (max-width: 480px) {
    font-size: 0.8rem;
  }
`;
