export const addressObj = {
  id: "contact",
  lightBg: false,
  lightText: false,
  lightTextDesc: true,
  topLine: "Address",
  headline: "Deivam Sarees",
  description: "Stockholm, Sweden",
  description2: "",
  buttonLabel: "Contact us",
  imgStart: false,
  img: require("../../images/undraw_online_shopping_re_k1sv.svg").default,
  alt: "Car",
  primaryBg: true,
  primary: false,
  darkText: true,
};

export const contentObj = {
  id: "intro",
  lightBg: false,
  lightText: false,
  lightTextDesc: true,
  topLine: "Welcome",
  headline: "Top quality clothings",
  description:
    "We sell top quality clothings such as sarees, blouses, veshits and kurtas for all ages. We choose our products depending on what is trending all around the world",
  description2: "",
  buttonLabel: "Shop now",
  imgStart: true,
  img: require("../../images/undraw_window_shopping_re_0kbm.svg").default,
  alt: "Car",
  primaryBg: true,
  primary: false,
  darkText: true,
};
