import React, { useRef, useCallback, useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";
import { MdClose } from "react-icons/md";
import styled from "styled-components";
import ReactDom from "react-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";

const { REACT_APP_API_HOST, REACT_APP_API_WEBSITE } = process.env;

export default function EditPopup({
  showItem,
  setShowItem,
  selectedItem,
  save,
}) {
  const [item, setItem] = useState(selectedItem);
  const [updatedItem, setUpdatedItem] = useState([]);

  const itemRef = useRef();

  const animation = useSpring({
    config: {
      duration: 350,
    },
    opacity: showItem ? 1 : 0,
  });

  const closeItemPreview = (e) => {
    if (itemRef.current === e.target) {
      window.history.back();
    }
  };

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showItem) {
        window.history.back();
      }
    },
    [showItem]
  );

  const handleChange = (event, key) => {
    const changedItem = Object.assign({}, item);
    const changedUpdatedItem = Object.assign({}, updatedItem);
    if (typeof changedItem[key] === "number") {
      if (isNaN(parseInt(event))) {
        event = 0;
      }
      changedItem[key] = parseInt(event);
      changedUpdatedItem[key] = parseInt(event);
    } else {
      changedItem[key] = event;
      changedUpdatedItem[key] = event;
    }
    setItem(changedItem);
    setUpdatedItem(changedUpdatedItem);
    console.log(item);
    console.log(changedUpdatedItem);
  };

  const handleObjectChange = (value, key) => {
    const changedItem = Object.assign({}, item);
    const changedUpdatedItem = Object.assign({}, updatedItem);
    if (isNaN(parseInt(value))) {
      value = 0;
    }
    changedItem[key] = { id: parseInt(value) };
    changedUpdatedItem[key] = { id: parseInt(value) };
    setItem(changedItem);
    setUpdatedItem(changedUpdatedItem);
    console.log(item);
    console.log(changedUpdatedItem);
  };

  const handleArrayChange = (value, key, index) => {
    const changedItem = Object.assign({}, item);
    const changedUpdatedItem = Object.assign({}, updatedItem);
    const array = changedItem[key];
    if (index < array.length) {
      if (value === "") {
        array.splice(index, 1);
      } else {
        array[index] = { id: parseInt(value) };
      }
    } else if (value !== "") {
      array.push({ id: parseInt(value) });
    }
    changedItem[key] = array;
    changedUpdatedItem[key] = array;
    setItem(changedItem);
    setUpdatedItem(changedUpdatedItem);
    console.log(item);
    console.log(changedUpdatedItem);
  };

  function populateArray(key, array) {
    const arr = [];
    for (let i = 0; i < array.length; i++) {
      arr.push(
        <SubInput
          key={i}
          value={array[i].id}
          onChange={(event) => handleArrayChange(event.target.value, key, i)}
        />
      );
    }
    arr.push(
      <SubInput
        key={arr.length}
        value=""
        onChange={(event) =>
          handleArrayChange(event.target.value, key, arr.length)
        }
      />
    );
    return arr;
  }

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => {
      document.removeEventListener("keydown", keyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    window.onpopstate = (e) => {
      setShowItem(false);
    };
    return () => {
      setShowItem(false);
    };
  }, [setShowItem]);

  useEffect(() => {
    window.history.pushState(
      "Saree preview",
      "Deivam sarees",
      "/admin?id=" + selectedItem.id
    );
  }, [selectedItem]);

  return ReactDom.createPortal(
    <>
      {showItem ? (
        <Background ref={itemRef} onClick={closeItemPreview}>
          <animated.div style={animation}>
            <ItemWrapper>
              <ThumbnailSection>
                {item.images.map((image) => (
                  <ImagePreview key={image.id}>
                    <PreviewImage
                      src={
                        REACT_APP_API_HOST +
                        "/api/v1/article/image/thumbnail/" +
                        image.path
                      }
                      alt="test"
                    />
                  </ImagePreview>
                ))}
              </ThumbnailSection>
              <WideInput placeholder="Bild namn" />

              <Button onClick={() => {}}>Upload Image</Button>
              <CopyToClipboard
                text={REACT_APP_API_WEBSITE + /shop/ + selectedItem.id}
                onCopy={() => alert("Copied to clipboard")}
              >
                <Button>Copy link</Button>
              </CopyToClipboard>

              <ItemContent>
                <SpecArea>
                  {Object.entries(item)
                    .filter(
                      ([key, value]) =>
                        key !== "@class" &&
                        key !== "images" &&
                        key !== "text1" &&
                        key !== "text2" &&
                        key !== "text3" &&
                        key !== "text4"
                    )
                    .map(([key, value]) => {
                      if (key === "id") {
                        return (
                          <Spec key={key}>
                            {key}
                            <Input
                              value={value !== null ? value : ""}
                              readOnly={true}
                            />
                          </Spec>
                        );
                      } else if (Array.isArray(value)) {
                        return (
                          <Spec key={key}>
                            {key}
                            <SubInputArea>
                              {populateArray(key, value)}
                            </SubInputArea>
                          </Spec>
                        );
                      } else if (key === "validTo") {
                        if (value === null) {
                          item[key] = "";
                        }
                        return (
                          <Spec key={key}>
                            {key}
                            <Input
                              value={value !== null ? value : ""}
                              onChange={(event) =>
                                handleChange(event.target.value, key)
                              }
                            />
                          </Spec>
                        );
                      } else if (typeof value === "object") {
                        return (
                          <Spec key={key}>
                            {key}
                            <Input
                              value={value !== null ? value.id : ""}
                              onChange={(event) =>
                                handleObjectChange(event.target.value, key)
                              }
                            />
                          </Spec>
                        );
                      } else if (typeof value === "boolean") {
                        return (
                          <Spec key={key}>
                            {key}
                            <Checkbox
                              checked={value !== null ? value : false}
                              onChange={(event) =>
                                handleChange(event.target.checked, key)
                              }
                            />
                          </Spec>
                        );
                      } else {
                        return (
                          <Spec key={key}>
                            {key}
                            <Input
                              value={value !== null ? value : ""}
                              onChange={(event) =>
                                handleChange(event.target.value, key, value)
                              }
                            />
                          </Spec>
                        );
                      }
                    })}
                </SpecArea>
                <Button
                  soldOut={selectedItem.inStock === 0}
                  onClick={() => save(updatedItem, item)}
                >
                  Save
                </Button>
              </ItemContent>
              <CloseItemPreviewButton
                aria-label="Close"
                onClick={() => window.history.back()}
              />
            </ItemWrapper>
          </animated.div>
        </Background>
      ) : null}
    </>,
    document.getElementById("portal")
  );
}

const Background = styled.div`
  top: 0;
  bottom: 0;
  padding: 0 1%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  transform: translateZ(0);
  overflow: hidden;

  @media screen and (max-width: 768px) {
    padding: 0 15px;
  }
`;

const ItemWrapper = styled.div`
  width: 100%;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: grid;
  grid-template-columns: 1fr 0.1fr 1fr;
  position: relative;
  z-index: 100;
  border-radius: 10px;
  overflow: scroll;
  height: 90vh;

  h1 {
    margin-bottom: -5px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
`;

const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  line-height: 1.8;
  color: #141414;
  padding-bottom: 5px;
`;

const PreviewImage = styled.img`
  height: 80px;
  width: 80px;
  @media screen and (max-width: 360px) {
    width: 50px;
    height: 50px;
  }
`;

const ImagePreview = styled.div`
  height: 80px;
  flex-direction: column;
  width: fit-content;
  display: grid;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    display: inline;
  }
`;

const ThumbnailSection = styled.div`
  flex-direction: row;
`;

const SpecArea = styled.div`
  margin-top: 5px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
`;

const Spec = styled.div`
  padding: 5px;
  display: block;
  line-height: 0.9rem;
  font-size: 0.8rem;
  margin-top: 5px;
`;

const Input = styled.input`
  margin-top: 5px;
  height: 30px;
  display: block;
  width: 40vw;
  padding-left: 5px;
`;

const SubInputArea = styled.div`
  display: block;
`;
const SubInput = styled.input`
  margin-top: 5px;
  margin-right: 5px;
  padding-left: 5px;
  height: 30px;
  display: inline;
  width: 30px;
  text-align: center;
`;

const CloseItemPreviewButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 1;
`;

const Button = styled.button`
  margin-top: 5px;

  padding: 10px 24px;
  color: #fff;
  border: none;
  background: #141414;

  @media screen and (max-width: 360px) {
    height: 40px;
  }
`;

const WideInput = styled.input`
  margin-top: 5px;
  height: 30px;
  display: block;
  width: 100%;
  padding-left: 5px;
`;

const Checkbox = styled.input.attrs({ type: "checkbox" })`
  margin-top: 5px;
  height: 25px;
  display: block;
  width: 25px;
  padding-left: 5px;
`;
