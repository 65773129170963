import React, { useState } from "react";
import styled from "styled-components";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";

const SearchBar = ({ setSearchText }) => {
  const [showClear, setShowClear] = useState(false);
  const [text, setText] = useState("");
  return (
    <SearchBarContainer>
      <SearchInput
        placeholder="Search a product..."
        value={text}
        onChange={(e) => {
          setText(e.target.value);
          setShowClear(e.target.value.length !== 0);
        }}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            setSearchText(e.target.value);
          }
        }}
      />
      <SearchIconContainer>
        {showClear ? (
          <div
            onClick={() => {
              setText("");
              setSearchText("");
              setShowClear(false);
            }}
          >
            <ClearIcon />
          </div>
        ) : (
          <SearchIcon />
        )}
      </SearchIconContainer>
    </SearchBarContainer>
  );
};

export default SearchBar;

const SearchBarContainer = styled.div`
  display: flex;
  background: #ffffff;
  color: #000000;
  cursor: pointer;
  margin-bottom: 0;
  width: 300px;
  border-radius: 30px;
  height: 35px;
  outline: none;
  transition: 0.15s;
  padding: 0 10px;

  &:active {
    background-color: #adadad;
  }

  &:focus {
    outline: none;
  }

  @media screen and (max-width: 1100px) {
    width: 100%;
  }
`;

const SearchInput = styled.input.attrs({
  type: "search",
})`
  background: transparent;
  border-color: transparent;
  outline: none;
  width: 100%;
  ::-ms-clear,
  ::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  /* clears the 'X' from Chrome */
  ::-webkit-search-decoration,
  ::-webkit-search-cancel-button,
  ::-webkit-search-results-button,
  ::-webkit-search-results-decoration {
    display: none;
  }
`;

const SearchIconContainer = styled.div`
  background-color: transparent;
  display: flex;
  place-items: center;
  font-size: 35px;
  &:active {
    background-color: #adadad;
  }

  &:focus {
    outline: none;
  }
`;
