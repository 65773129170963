import React from "react";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { animateScroll as scroll } from "react-scroll";

import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  FooterLinkA,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
} from "./FooterElement";

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>About us</FooterLinkTitle>
              <FooterLink to="/">FAQ</FooterLink>
              <FooterLink to="/">Terms of Serivce</FooterLink>
              <FooterLink to="/">Privacy Policy</FooterLink>
              <FooterLink to="/">Contact us</FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Shop</FooterLinkTitle>
              <FooterLink to="/shop">Female</FooterLink>
              <FooterLink to="/shop">Male</FooterLink>
              <FooterLink to="/shop">Children</FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Social media</FooterLinkTitle>
              <FooterLinkA href="https://www.instagram.com/deivamsarees/">
                Instagram
              </FooterLinkA>
              <FooterLinkA href="https://facebook.com/Deivamsarees/">
                Facebook
              </FooterLinkA>
              <FooterLinkA href="https://youtube.com/Deivamsarees/">
                Youtube
              </FooterLinkA>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/" onClick={toggleHome}>
              {" "}
              Deivam Sarees{" "}
            </SocialLogo>
            <WebsiteRights>
              {" "}
              Deivam Sarees © {new Date().getFullYear()} - All rights reserved.
            </WebsiteRights>
            <SocialIcons>
              <SocialIconLink
                href="//instagram.com/deivamsarees/"
                target="_blank"
                aria_label="Instagram"
              >
                <FaInstagram />
              </SocialIconLink>
              <SocialIconLink
                href="//facebook.com/Deivamsarees/"
                target="_blank"
                aria_label="Facebook"
              >
                <FaFacebook />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
