import React, { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";
const { REACT_APP_API_HOST } = process.env;
const override = css`
  display: block;
  position: relative;
  border-color: #313131;
`;

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);

  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(false);
    const response = await fetch(
      REACT_APP_API_HOST +
        "/login?username=" +
        data.username +
        "&password=" +
        data.password,
      {
        method: "POST",
      }
    );
    if (!response.ok) {
      setLoginFailed(true);
      return;
    }
    const token = await response.json();
    console.log(token.access_token);
    sessionStorage.setItem("access_token", token.access_token);
    sessionStorage.setItem("refresh_token", token.refresh_token);
    setLoading(false);
    navigate("/admin");
  };

  return (
    <Page>
      <LoadHolder>
        <MoonLoader
          color="#000000"
          loading={loading}
          css={override}
          size={50}
        />
      </LoadHolder>
      <InputContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          {loginFailed ? (
            <LoginFailed>Invalid password or username</LoginFailed>
          ) : null}
          <Input
            type="text"
            placeholder="Username"
            {...register("username", { required: true, maxLength: 80 })}
          />
          {errors.name?.type === "required" && (
            <ErrorMessage>Username is required</ErrorMessage>
          )}

          <Input
            type="password"
            placeholder="password"
            {...register("password", { required: true, maxLength: 100 })}
          />
          {errors.password && <ErrorMessage>Password is required</ErrorMessage>}

          <InputButton type="submit" value="Login" />
        </form>
      </InputContent>
    </Page>
  );
};

export default Login;

const LoadHolder = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%);
`;

const LoginFailed = styled.div`
  display: block;
  color: red;
`;

const Page = styled.div`
  width: 100%;
  height: 1000px;
  display: grid;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1300px) {
    height: 100vh;
  }
`;

const InputContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  line-height: 1.8;
  color: #141414;

  p {
    margin-bottom: 1rem;
  }
`;

const Input = styled.input`
  background: #fff;
  color: #000;
  border-color: #141414;
  width: 100%;
  height: 35px;
  margin-bottom: 10px;
  box-shadow: 0px;
  outline: none;
  transition: 0.15s;
  text-align: center;
  &:active {
    background-color: #8b8b8b;
  }
`;

const ErrorMessage = styled.span`
  color: red;
`;

const InputButton = styled.input.attrs({ type: "submit" })`
  display: flex;
  width: 100%;
  height: 35px;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px 24px;
  background: #141414;
  color: #fff;
  border: none;
  border-radius: 0;
  &:active {
    background-color: #8b8b8b;
  }
`;
