import React, { useRef, useCallback, useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";
import styled from "styled-components";
import ReactDom from "react-dom";

import Submit from "./Submit";
import Itempreview from "./Itempreview";
import Confirmation from "./Confirmation";
const { REACT_APP_API_HOST } = process.env;
export default function Popup({
  showItem,
  setShowItem,
  selectedItem,
  images,
  switchState,
  setSwitchState,
  currency,
}) {
  const [response, setResponse] = useState([]);
  //REQUEST-START

  const onSubmit = (data, callback) => {
    data.articleId = selectedItem.id;
    fetch(REACT_APP_API_HOST + "/api/v1/article/request", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((response) => {
      setResponse(response);
      callback();
      setSwitchState("confirmation");
    });
  };

  //REQUEST-END

  const itemRef = useRef();

  const animation = useSpring({
    config: {
      duration: 350,
    },
    opacity: showItem ? 1 : 0,
    height: "100%",
  });

  const closeItemPreview = (e) => {
    if (itemRef.current === e.target) {
      window.history.back();
    }
  };

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showItem) {
        window.history.back();
      }
    },
    [showItem]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => {
      document.removeEventListener("keydown", keyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    window.onpopstate = (e) => {
      setShowItem(false);
      setSwitchState("preview");
    };
    return () => {
      setShowItem(false);
    };
  }, [setShowItem, setSwitchState]);

  useEffect(() => {
    window.history.pushState(
      "Saree preview",
      "Deivam sarees",
      "/shop/" + selectedItem.id
    );
  }, [selectedItem]);

  const switchPages = () => {
    switch (switchState) {
      case "preview":
        return (
          <Itempreview
            showItem={showItem}
            setShowItem={setShowItem}
            images={images}
            selectedItem={selectedItem}
            setSwitchState={setSwitchState}
            currency={currency}
          />
        );
      case "submit":
        return (
          <Submit
            showItem={showItem}
            onSubmit={onSubmit}
            selectedItem={selectedItem}
            currency={currency}
          />
        );
      case "confirmation":
        return (
          <Confirmation
            showItem={showItem}
            setShowItem={setShowItem}
            images={images}
            selectedItem={selectedItem}
            setSwitchState={setSwitchState}
            response={response}
          />
        );
      default:
        return <h1>Nothing to show</h1>;
    }
  };

  return ReactDom.createPortal(
    <>
      {showItem ? (
        <Background ref={itemRef} onClick={closeItemPreview}>
          <animated.div style={animation}>{switchPages()}</animated.div>
        </Background>
      ) : null}
    </>,
    document.getElementById("portal")
  );
}

const Background = styled.div`
  top: 0;
  bottom: 0;
  padding: 0 1%;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  transform: translateZ(0);
  overflow: hidden;

  @media screen and (max-width: 768px) {
    padding: 0 15px;
  }
`;
