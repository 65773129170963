import React, { useState } from "react";
import styled from "styled-components";
import SearchBar from "./Searchbar";
import ReactSelect from "react-select";
import makeAnimated from "react-select/animated";
import { MdFilterList } from "react-icons/md";

const options = [
  { value: "Popularity", label: "Popular" },
  { value: "Default", label: "Recent" },
  { value: "PriceLowHigh", label: "Price low-high" },
  { value: "PriceHighLow", label: "Price high-low" },
];

const customStyles = {
  control: (styles) => ({
    ...styles,
    borderRadius: "30px",
    border: "0",
    background: "#fff",
  }),
};

const animatedComponents = makeAnimated();

const ShopNav = ({
  root,
  setSelectedCategory,
  categories,
  filters,
  handleFilterchange,
  setSearchText,
  setSort,
}) => {
  const [showFilter, setShowFilter] = useState(Boolean);

  return (
    <Nav>
      <NavbarContainer>
        <ContentRow1>
          <ColumnOne1>
            <SearchBar setSearchText={setSearchText} />
            <MdFilter onClick={() => setShowFilter(!showFilter)} />
          </ColumnOne1>
          <ColumnOne2>
            <NavMenu>
              <NavItem>
                <NavLinkm
                  tabIndex={-1}
                  onClick={
                    root.parentCategory != null
                      ? () => setSelectedCategory(root.parentCategory.id)
                      : undefined
                  }
                >
                  {root.parentCategory != null ? "Back" : root.name}
                </NavLinkm>
              </NavItem>
              {categories.map((category) => (
                <NavItem key={category.id}>
                  <NavLinkr
                    tabIndex={-1}
                    onClick={() => setSelectedCategory(category.id)}
                    key={category.id}
                  >
                    {category.name}
                  </NavLinkr>
                </NavItem>
              ))}
            </NavMenu>
          </ColumnOne2>
          <ColumnOne3></ColumnOne3>
        </ContentRow1>
        <ContentRow2 showFilter={showFilter}>
          <CustomSelect
            classNamePrefix={"Select"}
            options={options}
            styles={customStyles}
            defaultValue={{ value: "Default", label: "Popular" }}
            margin={"0 0 10px 5px"}
            onChange={(change) => setSort(change.value)}
          />
          {filters.map((filter) => (
            <CustomSelect
              isClearable
              key={filter.id}
              classNamePrefix={"Select"}
              closeMenuOnSelect={false}
              components={animatedComponents}
              placeholder={filter.name}
              options={filter.filters}
              styles={customStyles}
              aria-orientation="horizontal"
              margin={"0 0 10px 5px"}
              onChange={(change) => handleFilterchange(change, filter.id)}
            />
          ))}
        </ContentRow2>
      </NavbarContainer>
    </Nav>
  );
};

export default ShopNav;

const CustomSelect = styled(ReactSelect)`
  width: 100%;
  margin: 0 5px;

  & .Select__indicator Select__dropdown-indicator {
    border-color: transparent transparent blue;
    border-radius: 50px;
  }

  &.Select.is-open > .Select-control .Select-arrow {
    border-color: transparent transparent red;
  }
  .react-select__option {
    border-radius: 1000px;
  }

  @media screen and (max-width: 768px) {
    margin: ${(props) => props.margin};
    width: 48%;
  }
`;

const MdFilter = styled(MdFilterList)`
  text-align: center;
  font-size: 2rem;
  cursor: pointer;
  margin-left: 20px;

  transition: 200ms ease-in-out;

  @media screen and (min-width: 1000px) {
    :hover {
      color: #858585;
    }
  }

  @media screen and (max-width: 768px) {
    margin-left: 10px;
    margin-bottom: 10px;
  }
`;

const Nav = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
`;

const NavbarContainer = styled.div`
  z-index: 1;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const ContentRow1 = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr) minmax(auto, 2fr) minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: "col1 col2 col3";

  @media screen and (max-width: 1100px) {
    grid-template-areas: "col2" "col1";
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const ColumnOne1 = styled.div`
  padding: 0 15px;
  grid-area: col1;
  display: flex;
`;

const ColumnOne2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`;

const ColumnOne3 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col3;
  @media screen and (max-width: 1100px) {
    display: none;
  }
`;

const ContentRow2 = styled.div`
  display: flex;
  text-align: left;
  width: 100%;
  font-size: 12px;
  overflow: ${(props) => (props.showFilter ? "unset" : "hidden")};
  transition: max-height 0.2s ease-out, opacity 0.4s ease-in,
    padding 0.2s ease-in;
  max-height: ${(props) => (props.showFilter ? "300px" : "0")};
  opacity: ${(props) => (props.showFilter ? "100" : "0")};
  padding: ${(props) => (props.showFilter ? "10px" : "0")};

  @media screen and (max-width: 768px) {
    text-align: center;
    margin-left: 0;
    width: 100%;
    flex-wrap: wrap;
  }
`;

const NavMenu = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  text-align: center;
  justify-content: center;
`;

const NavItem = styled.li`
  font-weight: 700;
  letter-spacing: 2px;
`;

const NavLinkm = styled.a`
  color: var(--nav-text-color);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  cursor: pointer;
  margin: 0 14px;
  height: 25px;
  transition: all 0.2s ease-in-out;
  background-color: #dfdfdf;
  border-radius: 20px;

  &.active {
    border-bottom: 3px solid var(--nav-text-color-active);
  }

  &:hover {
    @media screen and (min-width: 1000px) {
      transition: all 0.2s ease-in-out;
      color: var(--main-text-color-hover);
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0 0.5rem;
    margin: 0 5px;
  }
`;

const NavLinkr = styled.a`
  color: var(--nav-text-color);
  display: flex;
  align-items: center;
  text-decoration: none;
  border-bottom: 1px solid #000;
  padding: 0 5px 5px 5px;
  cursor: pointer;
  font-weight: 500;
  letter-spacing: 2px;
  margin: 0 14px;
  height: 25px;
  transition: all 0.2s ease-in-out;

  &.active {
    border-bottom: 3px solid var(--nav-text-color-active);
  }

  &:hover {
    @media screen and (min-width: 1000px) {
      transition: all 0.2s ease-in-out;
      color: var(--main-text-color-hover);
    }
  }
  @media screen and (max-width: 768px) {
    padding: 0 1px;
    margin: 0 5px;
  }
`;
