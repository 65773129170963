import React, { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { css } from "@emotion/react";
import MoonLoader from "react-spinners/MoonLoader";
import { MdClose } from "react-icons/md";

const { REACT_APP_API_HOST } = process.env;

const override = css`
  display: block;
  position: relative;
  border-color: #313131;
`;

const Submit = ({ showItem, onSubmit, selectedItem, currency }) => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  console.log(selectedItem);

  const resetSubmit = () => {
    setLoading(false);
    reset("", {
      keepValues: false,
    });
  };

  const addCallback = (data) => {
    setLoading(true);
    onSubmit(data, resetSubmit);
  };

  return (
    <RequestWrapper showItem={showItem}>
      <LoadHolder>
        <MoonLoader
          color="#000000"
          loading={loading}
          css={override}
          size={50}
        />
      </LoadHolder>
      <CloseItemPreviewButton
        aria-label="Close"
        onClick={() => window.history.back()}
      />
      <ImagePreview>
        <PreviewImage
          src={
            REACT_APP_API_HOST +
            "/api/v1/article/image/med/" +
            selectedItem.firstImage.path
          }
          alt="test"
        />
      </ImagePreview>
      <InputContent>
        <TextArea>
          <BoldText>You are requesting</BoldText>
          {selectedItem.name}
          <br />
          Price: {(selectedItem.price * currency.rate).toFixed(2)}
          &nbsp;{currency.countryCode}
        </TextArea>
        <form onSubmit={handleSubmit(addCallback)}>
          <Input
            type="text"
            placeholder="Name"
            {...register("name", { required: true, maxLength: 80 })}
          />
          {errors.name?.type === "required" && (
            <ErrorMessage>First name is required</ErrorMessage>
          )}

          <Input
            type="email"
            placeholder="Mail"
            {...register("mail", { required: true, maxLength: 100 })}
          />
          {errors.mail && <ErrorMessage>Mail is required</ErrorMessage>}

          <InputText
            {...register("message", {})}
            placeholder="Message (Optional)"
          />

          <InputButton type="submit" />
        </form>
      </InputContent>
    </RequestWrapper>
  );
};

export default Submit;

const LoadHolder = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%);
`;

const RequestWrapper = styled.div`
  height: 80%;
  width: 60vw;
  justify-content: center;
  align-items: center;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: flex;
  z-index: 100;
  border-radius: 10px;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);

  @media screen and (max-width: 1100px) {
    display: grid;
    width: 80vw;
    max-height: 90%;
  }

  @media screen and (max-width: 400px) and (max-height: 700px) {
    height: 95%;
    width: 95%;
  }
`;

const InputContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  line-height: 1.8;
  color: #141414;

  p {
    margin-bottom: 1rem;
  }
`;

const Input = styled.input`
  background: #fff;
  color: #000;
  text-transform: uppercase;
  width: 100%;
  border-radius: 0;
  height: 35px;
  margin-bottom: 10px;
  border-color: transparent;
  box-shadow: 0px;
  transition: 0.15s;
  text-align: center;
  border-bottom: 1px solid #000;
  &:active {
    background-color: #8b8b8b;
  }
  @media screen and (max-width: 400px) and (max-height: 700px) {
    font-size: 10px;
    height: 20px;
  }
`;

const ErrorMessage = styled.span`
  color: red;
`;

const InputText = styled.textarea`
  background: #fff;
  color: #000;
  text-transform: uppercase;
  width: 100%;
  height: 80px;
  margin-bottom: 10px;
  border-color: transparent;
  box-shadow: 0px;
  transition: 0.15s;
  text-align: left;
  border: 1px solid #000;
  border-radius: 0;

  &:active {
    background-color: #8b8b8b;
  }

  @media screen and (max-width: 400px) and (max-height: 700px) {
    height: 50px;
    font-size: 10px;
  }
`;

const InputButton = styled.input.attrs({ type: "submit" })`
  -webkit-appearance: none;
  -moz-appearance: none;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px 24px;
  background: #141414;
  color: #fff;
  border: none;
  border-radius: 0;
  &:active {
    background-color: #8b8b8b;
  }

  @media screen and (max-width: 400px) and (max-height: 700px) {
    font-size: 12px;
    height: 30px;
  }
`;

const ImagePreview = styled.div`
  width: 100%;
  background-color: #fff;

  width: 100%;
  text-align: center;
`;

const PreviewImage = styled.img`
  width: 100%;

  @media screen and (max-width: 768px) {
    max-height: 35vh;
  }
`;

const TextArea = styled.div`
  height: 120px;
  width: 100%;
  background-color: #fff;

  @media screen and (max-width: 1100px) {
    height: 100%;
  }

  @media screen and (max-width: 400px) and (max-height: 700px) {
    font-size: 12px;
    height: 100%;
  }
`;

const BoldText = styled.div`
  font-weight: 700;
  @media screen and (max-width: 400px) and (max-height: 700px) {
    font-size: 12px;
  }
`;

const CloseItemPreviewButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 1;
`;
