//import { useState, useEffect } from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import "./css/App.css";
import Home from "./pages";
import ShopPage from "./pages/shop";
import { useEffect } from "react";
import Login from "./pages/login";
import Admin from "./components/AdminSection/admin";
const { REACT_APP_API_HOST } = process.env;

function App() {
  useEffect(() => {
    const addCount = async () => {
      const res = await fetch(REACT_APP_API_HOST + "/api/v1/article/count");
      const data = await res.json();
      return data;
    };

    addCount();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route exact path="/shop" element={<ShopPage />} />
        <Route path="/shop/:item_id" element={<ShopPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={<Admin />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;

