import React from "react";
import {
  SidebarContainer,
  Icon,
  SearchIcon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLinkR,
} from "./SidebarElements";

const Sidebar = ({ isOpen, toggle }) => {
  var animationTimer = 0;

  return (
    <>
      <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <SearchIcon onClick={toggle} />
        <Icon>
          <CloseIcon />
        </Icon>
        <SidebarWrapper>
          <SidebarMenu>
            <SidebarLinkR
              $animationTimer={animationTimer++}
              $isOpen={isOpen}
              to="/"
              onClick={toggle}
            >
              Home
            </SidebarLinkR>
            <SidebarLinkR
              $animationTimer={animationTimer++}
              $isOpen={isOpen}
              to="/shop"
              onClick={toggle}
            >
              Shop
            </SidebarLinkR>
            <SidebarLinkR
              $animationTimer={animationTimer++}
              $isOpen={isOpen}
              to="/"
              onClick={toggle}
            >
              Contact
            </SidebarLinkR>
          </SidebarMenu>
        </SidebarWrapper>
      </SidebarContainer>
    </>
  );
};

export default Sidebar;
