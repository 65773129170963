import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link } from "react-scroll";

export const Button = styled(Link)`
  border-radius: 50px;
  background: ${({ primary }) =>
    primary
      ? "var(--button-primary-bgcolor)"
      : "var(--button-secondary-bgcolor)"};

  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};

  color: ${({ dark }) =>
    dark ? "var(--button-primary-color)" : "var(--button-secondary-color)"};

  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) =>
      primary
        ? "var(--button-primary-bgcolor-hover)"
        : "var(--button-secondary-bgcolor-hover)"};
  }
`;

export const RouterButton = styled(LinkR)`
  border-radius: 50px;
  background: ${({ primary }) =>
    primary
      ? "var(--button-primary-bgcolor)"
      : "var(--button-secondary-bgcolor)"};

  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};

  color: ${({ dark }) =>
    dark ? "var(--button-primary-color)" : "var(--button-secondary-color)"};

  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) =>
      primary
        ? "var(--button-primary-bgcolor-hover)"
        : "var(--button-secondary-bgcolor-hover)"};
  }
`;
