import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import "react-notifications/lib/notifications.css";
import EditPopup from "./EditPopup";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import AddPopup from "./AddPopup";

const Admin = () => {
  const [articles, setArticles] = useState([]);
  const [searchPhrase, setSearchPhrase] = useState([]);

  const [showItem, setShowItem] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const [selectedItem, setSelectedItem] = useState([]);
  const { REACT_APP_API_HOST } = process.env;
  let navigate = useNavigate();

  const resetToken = useCallback(
    async (callback) => {
      const response = await fetch(
        REACT_APP_API_HOST + "/api/v1/security/token/refresh",
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("refresh_token"),
          },
        }
      );

      if (!response.ok) {
        navigate("/login");
        return;
      }
      const token = await response.json();
      sessionStorage.setItem("access_token", token.access_token);
      sessionStorage.setItem("refresh_token", token.refresh_token);
      console.log("Resetted token");
      return callback();
    },
    [navigate, REACT_APP_API_HOST]
  );

  const fetchArticles = useCallback(async () => {
    console.log(sessionStorage.getItem("access_token"));
    const res = await fetch(REACT_APP_API_HOST + "/api/admin/v1/articles", {
      credentials: "same-origin",
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
      },
    });
    if (res.status === 403) {
      return resetToken(fetchArticles);
    } else if (!res.ok) {
      navigate("/login");
      return;
    }
    const data = await res.json();
    console.log(data);
    return data;
  }, [navigate, resetToken, REACT_APP_API_HOST]);

  function save(updatedItem, item) {
    if (item.id !== null) {
      updatedItem.id = item.id;
    }
    updatedItem["@class"] = item["@class"];
    console.log(updatedItem);
    fetch(REACT_APP_API_HOST + "/api/admin/v1/article", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
      },
      body: JSON.stringify(updatedItem),
    }).then((res) => {
      if (!res.ok) {
        console.log("YEES");
        NotificationManager.error(
          "Hoppsan, ett fel uppstod! Vänligen kontakta Aigeth..."
        );
        return;
      }
      const getArticles = async () => {
        const articlesFromServer = await fetchArticles();
        setArticles(articlesFromServer);
      };

      getArticles();
      setShowAdd(false);
      setShowItem(false);
    });
  }

  const openItem = (article) => {
    setSelectedItem(article);
    setShowItem((prev) => !prev);
  };

  useEffect(() => {
    const getArticles = async () => {
      const articlesFromServer = await fetchArticles();
      setArticles(articlesFromServer);
    };

    getArticles();
  }, [fetchArticles]);

  const findItem = () => {
    const article = articles.find((article) => article.id === searchPhrase);
    openItem(article);
  };

  const deleteUnsetImages = useCallback(async () => {
    console.log(sessionStorage.getItem("access_token"));
    const res = await fetch(REACT_APP_API_HOST + "/api/admin/v1/images", {
      method: "DELETE",
      credentials: "same-origin",
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
      },
    });
    if (res.status === 403) {
      return resetToken(deleteUnsetImages);
    } else if (!res.ok) {
      navigate("/login");
      return;
    }
  }, [navigate, resetToken, REACT_APP_API_HOST]);

  const deleteArticle = async () => {
    if (!window.confirm("Är du säker på att du vill ta bort?")) {
      return;
    }
    var url = new URL(REACT_APP_API_HOST + "/api/admin/v1/article/");
    var params = { ids: selectedItems };
    url.search = new URLSearchParams(params).toString();

    const res = await fetch(url, {
      method: "DELETE",
      credentials: "same-origin",
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
      },
    });
    if (res.status === 403) {
      return resetToken(() => deleteArticle());
    } else if (!res.ok) {
      navigate("/login");
      return;
    }
    const articlesFromServer = await fetchArticles();
    setArticles(articlesFromServer);
    setSelectedItems([]);
  };

  return (
    <>
      <InputArea>
        <Input
          placeholder="Sök Id"
          onChange={(event) => setSearchPhrase(parseInt(event.target.value))}
          onKeyPress={(e) => {
            console.log(e.key);
            if (e.key === "Enter") {
              findItem();
            }
          }}
        />
        <Button onClick={findItem} enabled={true}>
          Ok{" "}
        </Button>
        <Button onClick={() => setShowAdd((prev) => !prev)} enabled={true}>
          Lägg till
        </Button>
        <Button
          onClick={() => {
            if (selectedItems.length > 0) {
              deleteArticle();
            }
          }}
          enabled={selectedItems.length > 0}
        >
          Tabort markerad ({selectedItems.length})
        </Button>
      </InputArea>

      <Page>
        {showItem ? (
          <EditPopup
            showItem={showItem}
            setShowItem={setShowItem}
            selectedItem={selectedItem}
            save={save}
          />
        ) : null}
        {showAdd ? (
          <AddPopup
            showItem={showAdd}
            setShowItem={setShowAdd}
            save={save}
            deleteUnsetImages={deleteUnsetImages}
          />
        ) : null}
        {articles.map((article) => (
          <Item key={article.id}>
            <RemoveContainer>
              <RemoveSelection
                onChange={(event) => {
                  if (event.target.checked) {
                    setSelectedItems((oldItems) => [...oldItems, article.id]);
                  } else {
                    setSelectedItems((currentItems) =>
                      currentItems.filter((item) => item !== article.id)
                    );
                  }
                }}
              />
            </RemoveContainer>

            <ImagePreview onClick={() => openItem(article)}>
              <PreviewImage
                src={
                  REACT_APP_API_HOST +
                  "/api/v1/article/image/thumbnail/" +
                  article.firstImage.path
                }
                alt="test"
              />
            </ImagePreview>
            <Text>
              ID: <Info>{article.id}</Info>
              {article.preOrder ? <Preorder>Pre order</Preorder> : null}
            </Text>
            <Text>
              Name: <br></br> <Info>{article.name}</Info>
            </Text>
          </Item>
        ))}
        <NotificationContainer />
      </Page>
    </>
  );
};

export default Admin;

const Page = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 5%;
  grid-row-gap: 5px;
  padding-left: 5%;
  padding-right: 5%;
  align-items: center;
  justify-content: center;
  border-color: black;
  border: 5px;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Item = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 5fr;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid black;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Text = styled.div`
  margin-left: 10px;
  @media screen and (max-width: 768px) {
    margin: 0;
  }
`;

const PreviewImage = styled.img`
  display: inline;
  height: 100px;
  width: 120px;
`;

const ImagePreview = styled.div`
  width: fit-content;
  @media screen and (max-width: 768px) {
    display: inline;
  }
`;

const Preorder = styled.div`
  display: inline;
  font-weight: 800;
  background-color: lightgray;
`;

const Info = styled.div`
  background: #fff;
  color: #000;
  border-color: #141414;
  width: fit-content;
  max-width: 350px;
  height: 100%;
  margin-bottom: 5px;
  box-shadow: 0px;
  outline: none;
  transition: 0.15s;
  text-align: center;
  &:active {
    background-color: #8b8b8b;
  }

  @media screen and (max-width: 768px) {
    text-align: left;
  }
`;

const InputArea = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

const Input = styled.input`
  margin-top: 5px;
  height: 30px;
  display: block;
  width: 150px;
  padding-left: 5px;

  text-align: center;
`;

const Button = styled.button`
  display: inline;
  margin-top: 6px;
  margin-left: 10px;
  height: 35px;
  padding: 10px 24px;
  color: #fff;
  border: none;
  background: ${({ enabled }) => (enabled ? "#141414" : "#818181")};
`;

const RemoveSelection = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  display: block;
  left: 77%;
  height: 35px;
  width: 35px;
  top: 0;
`;

const RemoveContainer = styled.div`
  position: relative;
  display: block;
`;
