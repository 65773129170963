import React from "react";
import styled from "styled-components";
import { useState, useEffect } from "react";
import { MdArrowLeft, MdArrowRight } from "react-icons/md";

import Popup from "./Popup";
import ShopNav from "./ShopNav";
import { useParams } from "react-router-dom";
const { REACT_APP_API_HOST } = process.env;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const Shop = () => {
  const [showItem, setShowItem] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [root, setRoot] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [categories, setCategories] = useState([]);
  const [articles, setArticles] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [images, setImages] = useState([]);
  const [imageUrl, setImageUrl] = useState([]);
  const [switchState, setSwitchState] = useState("preview");
  const [filters, setFilters] = useState([]);
  const [sort, setSort] = useState([]);
  const [currency, setCurrency] = useState({ countryCode: "SEK", rate: 1.0 });
  const [maxPages, setMaxPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  let { item_id } = useParams();

  const handleResize = () => {
    if (getWindowDimensions().width < 768) {
      setImageUrl(REACT_APP_API_HOST + "/api/v1/article/image/low/");
    } else if (getWindowDimensions().width < 1100) {
      setImageUrl(REACT_APP_API_HOST + "/api/v1/article/image/med/");
    } else {
      setImageUrl(REACT_APP_API_HOST + "/api/v1/article/image/med/");
    }
  };

  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchArticles = async (page) => {
    var string = REACT_APP_API_HOST + "/api/v2/article?id=" + selectedCategory;
    if (sort !== undefined && sort !== null && sort !== "") {
      string = string.concat("&sort=" + sort);
    }
    if (searchText !== undefined && searchText !== null && searchText !== "") {
      string = string.concat("&search=" + searchText);
    }

    if (
      selectedFilters !== undefined &&
      selectedFilters !== null &&
      Array.isArray(selectedFilters) &&
      selectedFilters.length !== 0
    ) {
      string = string.concat("&filters=");
      selectedFilters.forEach(
        (element) => (string = string.concat(element.filter.id + ","))
      );
      string = string.slice(0, -1);
    }

    string = string.concat("&page=" + page);

    console.log(string);

    const res = await fetch(string);
    const data = await res.json();
    return data;
  };

  useEffect(() => {
    const getCurrency = async () => {
      const currency = await fetchCurrency();
      setCurrency(currency);
    };
    getCurrency();
  }, []);

  useEffect(() => {
    const getArticles = async () => {
      const wrapper = await fetchArticles(0);
      setMaxPages(wrapper.pages);
      setArticles(wrapper.articles);
    };
    const getFilters = async (rootFromServer) => {
      const filters = await fetchFilters(rootFromServer.id);
      setFilters(filters);
    };

    const getCategories = async () => {
      const rootFromServer = await fetchRoot(selectedCategory);
      setRoot(rootFromServer);
      const categoriesFromServer = await fetchCategories(rootFromServer);
      setCategories(categoriesFromServer);
      if (currentPage === 0) {
        getArticles();
      } else {
        setCurrentPage(0);
      }
      getFilters(rootFromServer);
    };

    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters, searchText, sort, selectedCategory]);

  const fetchRoot = async (rootId) => {
    const res = await fetch(
      REACT_APP_API_HOST + "/api/v1/category/root/" + rootId
    );
    const data = await res.json();
    return data;
  };

  const handleFilterchange = (filter, id) => {
    const changedItem = Object.assign([], selectedFilters);
    const oldFilter = changedItem.find((element) => element.id === id);
    if (oldFilter !== undefined) {
      if (filter === null || filter === "") {
        changedItem.splice(changedItem.indexOf(oldFilter), 1);
      } else {
        changedItem[changedItem.indexOf(oldFilter)] = {
          id: id,
          filter: filter,
        };
      }
    } else {
      changedItem.push({ id: id, filter: filter });
    }
    setSelectedFilters(changedItem);
  };

  const fetchCategories = async (rootFromServer) => {
    const res = await fetch(
      REACT_APP_API_HOST + "/api/v1/category/parent/" + rootFromServer.id
    );
    const data = await res.json();
    return data;
  };

  const fetchArticle = async (id) => {
    const res = await fetch(REACT_APP_API_HOST + "/api/v1/article/" + id);
    const data = await res.json();
    return data;
  };

  const fetchCurrency = async () => {
    const ipJson = await fetch("https://ipapi.co/json/");
    const ip = await ipJson.json();
    const res = await fetch(
      REACT_APP_API_HOST + "/api/v2/article/currency/" + ip.ip
    );
    const data = await res.json();
    return data;
  };

  const fetchImages = async (articleId) => {
    const res = await fetch(
      REACT_APP_API_HOST + "/api/v1/article/image/article/" + articleId
    );
    const data = await res.json();
    return data;
  };

  const fetchFilters = async (categoryId) => {
    const res = await fetch(
      REACT_APP_API_HOST + "/api/v1/filter/" + categoryId
    );
    const data = await res.json();
    return data;
  };

  const openItem = (article) => {
    fetchImages(article.id).then((imagesFromServer) => {
      setImages(imagesFromServer);
      setSelectedItem(article);
      setShowItem(true);
    });
  };

  const handleQueryParams = React.useCallback((id) => {
    fetchArticle(id).then((response) => {
      fetchImages(response.id).then((imagesFromServer) => {
        setImages(imagesFromServer);
        setSelectedItem(response);
        window.history.replaceState(null, "", "/shop");
        setShowItem(true);
      });
    });
  }, []);

  useEffect(() => {
    fetchArticles(currentPage).then((wrapper) => {
      setMaxPages(wrapper.pages);
      setArticles(wrapper.articles);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  function changePage(direction) {
    if (currentPage + direction < 0 || currentPage + direction > maxPages) {
      return;
    }

    if (direction > 0) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    setCurrentPage(currentPage + direction);
  }

  useEffect(() => {
    if (item_id != null && !showItem) {
      handleQueryParams(item_id);
    }
  }, [item_id, handleQueryParams, showItem]);

  useEffect(() => {
    var page = document.getElementsByTagName("body")[0];
    showItem
      ? page.classList.add("noscroll")
      : page.classList.remove("noscroll");
  }, [showItem]);

  return (
    <ShopContainer>
      {showItem ? (
        <Popup
          showItem={showItem}
          setShowItem={setShowItem}
          selectedItem={selectedItem}
          images={images}
          switchState={switchState}
          setSwitchState={setSwitchState}
          currency={currency}
        />
      ) : null}
      <ShopNav
        root={root}
        setSelectedCategory={setSelectedCategory}
        categories={categories}
        filters={filters}
        handleFilterchange={handleFilterchange}
        setSort={setSort}
        setSearchText={setSearchText}
      />

      <ShopWrapper>
        {articles.map((article) => (
          <ShopCard onClick={() => openItem(article)} key={article.id}>
            {article.inStock === 0 ? <SoldOut>Sold out</SoldOut> : null}
            <ItemImage src={imageUrl + article.firstImage.path}></ItemImage>
            <ItemDescription>{article.name}</ItemDescription>
            <ItemPrice>
              {(article.price * currency.rate).toFixed(2)}
              &nbsp;{currency.countryCode}
            </ItemPrice>
          </ShopCard>
        ))}
      </ShopWrapper>
      <PagesContainer>
        <LeftArrow size={45} onClick={() => changePage(-1)} />
        <PagesArea>
          {"Page " + (currentPage + 1) + " of " + (maxPages + 1)}
        </PagesArea>
        <RightArrow size={45} onClick={() => changePage(1)} />
      </PagesContainer>
    </ShopContainer>
  );
};

export default Shop;

const ShopContainer = styled.div`
  margin-top: 80px;
  padding-top: 30px;
  padding-bottom: 10px;
  background: var(--shop-bg-color);
`;

const ShopWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  max-width: 100vw;
  @media screen and (max-width: 1300px) {
    grid-gap: 8px;
    grid-template-columns: 1fr 1fr;
  }
`;

const ShopCard = styled.div`
  align-items: center;
  text-align: center;
  padding: 20px 10px 20px 10px;
  margin-bottom: 20px;
  transition: all 0.2s ease-in-out;

  &:hover {
    @media screen and (min-width: 1300px) {
      transform: scale(1.02);
      transition: all 0.2s ease-in-out;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1300px) {
    padding: 10px 5px;
    margin-bottom: 0px;
    height: 100%;
  }
`;

const ItemImage = styled.img`
  width: 100%;
  height: 70vh;
  -o-object-fit: cover;
  object-fit: cover;
  margin-bottom: 20px;

  @media screen and (max-width: 1100px) {
    width: 100%;
    height: 500px;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    height: 187px;
  }
`;

const LeftArrow = styled(MdArrowLeft)`
  cursor: pointer;
`;

const RightArrow = styled(MdArrowRight)`
  cursor: pointer;
`;

const ItemDescription = styled.h3`
  display: block;
  font-size: 1rem;
  margin-bottom: 5px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  @media screen and (max-width: 480px) {
    font-size: 0.8rem;
  }
`;

const SoldOut = styled.div`
  color: red;
  padding: 20px;
  font-weight: 800;
  background-color: #00000063;
  display: flex;
  position: absolute;
`;

const ItemPrice = styled.h4`
  display: block;
  font-size: 0.8rem;
  text-align: center;
  font-family: "Poppins", sans-serif;
  @media screen and (max-width: 480px) {
    font-size: 0.7rem;
  }
`;

const PagesContainer = styled.ul`
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const PagesArea = styled.ul`
  padding: 13px 0;
`;
